import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/MovedIdeas";
import resolveSideScroll from "@dataResolvers/resolveSideScroll";
import resolveImage from "@dataResolvers/resolveImage";
// eslint-disable-next-line no-unused-vars
import ImageFragment from "@fragments/ImageFragment";
// eslint-disable-next-line no-unused-vars
import StoriesFragment from "@fragments/StoriesFragment";
// eslint-disable-next-line no-unused-vars
import SideScrollFragment from "@fragments/SideScrollFragment";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query WeMovedIdeasQuery($siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "weMovedIdeas", siteId: $siteId) {
        ... on Craft_weMovedIdeas_weMovedIdeas_Entry {
          title
          metaTitle
          metaDescription
          metaImage {
            ...ImageFragment
          }
          # intro
          introHeading: heading0
          introDescriptor: descriptor0
          introImage: image0 {
            ...ImageFragment
          }
          # drivers of change
          driversHeading: heading1
          driversCopy: copy0
          driversImage: image1 {
            ...ImageFragment
          }
          # making momentum
          momentumHeading: heading2
          momentumSideScroll: sideScrollEmbed0 {
            ...SideScrollFragment
          }
          # climate defenders
          climateHeading: heading3
          climateSideScroll: sideScrollEmbed1 {
            ...SideScrollFragment
          }
          # feminist foreign policy
          feministLabel: label0
          feministHeading: heading4
          feministDescription: descriptor1
          feministScrollHeading: heading5
          feministSideScroll: sideScrollEmbed2 {
            ...SideScrollFragment
          }
          # gender lens
          genderLabel: label1
          genderHeading: heading6
          genderDescription: descriptor2
          genderScrollHeading: heading7
          genderSideScroll: sideScrollEmbed3 {
            ...SideScrollFragment
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const {
    title,
    // intro,
    introHeading,
    introDescriptor,
    introImage,
    // drivers of change
    driversHeading,
    driversCopy,
    driversImage,
    // making momentum
    momentumHeading,
    momentumSideScroll,
    // climate defenders
    climateHeading,
    climateSideScroll,
    // foreign policy
    feministLabel,
    feministHeading,
    feministDescription,
    feministScrollHeading,
    feministSideScroll,
    // gender lens
    genderLabel,
    genderHeading,
    genderDescription,
    genderScrollHeading,
    genderSideScroll,
  } = entry;

  return {
    meta: {
      title,
    },
    title,
    intro: {
      heading: introHeading,
      description: introDescriptor,
      image: resolveImage(introImage),
    },
    driversOfChange: {
      heading: driversHeading,
      copy: driversCopy,
      image: resolveImage(driversImage),
    },
    momentum: {
      heading: momentumHeading,
      sideScroll: resolveSideScroll(momentumSideScroll),
    },
    climateDefenders: {
      heading: climateHeading,
      sideScroll: resolveSideScroll(climateSideScroll),
    },
    foreignPolicy: {
      label: feministLabel,
      heading: feministHeading,
      description: feministDescription,
    },
    foreignPolicyScroll: {
      heading: feministScrollHeading,
      sideScroll: resolveSideScroll(feministSideScroll),
    },
    genderLense: {
      label: genderLabel,
      heading: genderHeading,
      description: genderDescription,
    },
    genderLenseScroll: {
      heading: genderScrollHeading,
      sideScroll: resolveSideScroll(genderSideScroll),
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="WeMovedMoneyQuery"
    {...props}
  />
);

export default Page;

import React from "react";
import loadable from "@loadable/component";
import { Section } from "@atoms";

const DefaultPageContainer = loadable(() =>
  import("@templates/DefaultPageContainer")
);
const Frame = loadable(() => import("@templates/Frame"));
const HeroImage = loadable(() => import("@organisms/HeroImage"));
const SectionIntro = loadable(() => import("@organisms/SectionIntro"));
const GrowingImageCopy = loadable(() => import("@templates/GrowingImageCopy"));
const GrowingImageSlide = loadable(() =>
  import("@templates/GrowingImageSlide")
);

const DreamPage = ({ data, ...props }) => {
  const {
    title,
    intro,
    driversOfChange,
    momentum,
    climateDefenders,
    foreignPolicy,
    foreignPolicyScroll,
    genderLenseScroll,
  } = data;

  return (
    <DefaultPageContainer {...props}>
      <Frame
        title={intro?.heading || title}
        previous="/we-moved-money"
        next="/we-moved-together"
      />
      <HeroImage {...intro} />
      {/* drivers of change */}
      <Section anchor="change">
        <div className="relative z-10 my-12 border-b-3 border-white md:ml-14" />
        <GrowingImageCopy {...driversOfChange} smallerSpacer />
      </Section>
      {/* making movement in london */}
      <Section anchor="making-movement">
        <div className="relative z-10 border-b-3 border-white md:ml-14" />
        <GrowingImageSlide {...momentum} headingAlign="center" />
      </Section>
      {/* climate defenders */}
      <Section anchor="climate-defenders">
        <div className="relative z-10 mb-12 border-b-3 border-white md:ml-14" />
        <GrowingImageSlide {...climateDefenders} headingAlign="center" />
      </Section>
      {/* foreign policy */}
      <Section anchor="foreign-policy">
        <div className="relative z-10 mb-12 border-b-3 border-white md:ml-14" />
        <SectionIntro {...foreignPolicy} bottomIcon />
        <div className="relative z-10 border-b-3 border-white md:ml-14" />
        <GrowingImageSlide {...foreignPolicyScroll} headingAlign="center" />
      </Section>
      {/* gender lense funding */}
      <Section anchor="gender-lense">
        {/* <div className="relative z-10 mb-12 border-b-3 border-white md:ml-14" />
        <SectionIntro {...genderLense} bottomIcon /> */}
        <div className="relative z-10 border-b-3 border-white md:ml-14" />
        <GrowingImageSlide {...genderLenseScroll} headingAlign="center" />
      </Section>
    </DefaultPageContainer>
  );
};

DreamPage.defaultProps = {};

export default DreamPage;
